import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import VueLodash from 'vue-lodash'

import { WebAppDuplicateModal } from '@/modules/runcloud'
import '@/modules/firebase'

Vue.config.productionTip = false

// Vue.use(VueLodash)
Vue.use(require('vue-moment'))

Vue.component('WebAppDuplicateModal', WebAppDuplicateModal)

let app
Vue.prototype.$firebase.auth().onAuthStateChanged( (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
} )

