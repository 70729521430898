<template>
  <nav>
    <v-navigation-drawer app v-model="drawer" temporary floating>
      <p>test</p>
    </v-navigation-drawer>

    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>AdminVUE</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <span>{{ user.email }} </span>
      <v-btn text @click="logout">Logout</v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      user: {}
    };
  },
  mounted() {
    this.user = this.$firebase.auth().currentUser
  },
  methods: {
    logout() {
      console.log(this.$firebase)
      this.$firebase.auth().signOut()
        .then( () => {
          this.$router.replace('login')
        }) 
    }
  }

};
</script>