<template>
  <v-card>
    
    <v-card-title primary-title>
      <div>
        <h5 class="headline mb-0">RunCloud Servers</h5>
      </div>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="server in servers" :key="server.id" >
          <v-expansion-panel-header>{{ server.name }} - {{ server.ipAddress }}</v-expansion-panel-header>

          <v-expansion-panel-content>
            <ul>
              <li><span>IP</span>:        {{ server.ipAddress }}</li>
              <li><span>OS</span>:        {{ server.os }}</li>
              <li><span>Provider</span>:  {{ server.provider }}</li>
              <li><span>Online</span>:    {{ server.online ? 'YES' : 'NO' }}</li>
              <li><span>Created</span>:   {{ server.created_at }}</li>
            </ul>
          </v-expansion-panel-content>
          
        </v-expansion-panel>
        
        
      </v-expansion-panels>
      
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" dark>text</v-btn>
      
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'ServerList',
  computed: mapState({
    servers: state => state.runcloud.servers
  }),

  data() {
    return {}
  },

  mounted() {
    
  }
};
</script>