<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>WebApps</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-btn class tile color="primary" @click="$emit('openForm')">
        <v-icon left>mdi-pencil</v-icon>Create WebApp
      </v-btn>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col cols="8">
          <v-text-field outlined v-model="search" label="Search" @keyup.enter="getData" single-line hide-details></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select outlined
            :items="servers"
            label="Selected Server"
            :value="selectedServer"
            item-text="name"
            item-value="id"
            @input="setValue"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-card-text>
          <v-data-table
            :headers="header"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="options"
            :loading="loading"
            :items-per-page="15"
            :search="search"
            
            dense
          >
            <template v-slot:item._created="{ item }">{{ item.created_at | moment("MMM Do YY") }}</template>
            <template v-slot:item.actions="{ item }">
              <v-btn text small color="primary" @click="viewDetails(item)">
                <v-icon small>mdi-pencil</v-icon>
                Edit
              </v-btn>
              <v-btn text small color="primary" @click="cloneItem(item)">
                <v-icon small>mdi-content-copy</v-icon>
                Duplicate
              </v-btn>
              <v-btn text small color="red" @click="deleteItem(item)">
                <v-icon small>mdi-delete</v-icon>
                Delete
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions></v-card-actions>
      </v-row>
    </v-container>
    <WebAppDuplicateModal 
        :show='modalDuplicateWebApp' 
        :webapp="modalItem"
        @modalChange=" (value) => { modalDuplicateWebApp = value} ">
    </WebAppDuplicateModal> 
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { tableHeader } from "../config.js";

export default {
  name: "WebAppsList",
  data() {
    return {
      selectedServer: parseInt(localStorage.activeServer) || 40325,
      header: tableHeader,
      items: [],
      totalItems: 0,
      options: {},
      loading: false,
      search: "",
      modalDuplicateWebApp: false,
      modalItem: {}
    };
  },

  computed: {
    ...mapState({
      servers: state => state.runcloud.servers
    })
  },

  watch: {
    options: {
      handler() {
        if (this.selectedServer) this.setValue(this.selectedServer)
      }
    }
  },

  methods: {

    deleteItem(item) {
      alert('This feature is disabled')
      return

      console.log(item)
      const args = { serverId: this.selectedServer, webApp: item}
      this.$store.dispatch('runcloud/deleteWebApp', args)
        .then( resp => { 
          this.getData()
        })



    },
    cloneItem(item) {
      this.modalItem = item
      this.modalDuplicateWebApp = true
    },

    setValue(v) {
      this.selectedServer = v;
      this.$store.commit("runcloud/setActiveServer", this.selectedServer);
      localStorage.activeServer = this.selectedServer;
      this.getData();
    },
    getData() {
      // console.log(tableHeader)
      if (this.selectedServer == "") return;
      this.loading = true;
      this.options.search = this.search;

      this.$store
        .dispatch("runcloud/getAllWebApps", { options: this.options })
        .then(resp => {
          this.items = resp.data.data;
          this.totalItems = resp.data.meta.pagination.total;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    viewDetails(item) {
      this.$emit("edit", item);
    }
  },
  mounted() {
    //this.setValue(this.selectedServer);
  }
};
</script>