import axios from 'axios'

const API_KEY = 'bvw5A1x95460KskMb12JurJApdCLz3IFTiWH9J8nPSuZ'
const SECRET = 'odLloBjinex330guDQ1BzMkiXWdsPINY4wfhQhK0t6rdKLvX7qG4GNKoxgEKYiGv'
const URL = process.env.VUE_APP_RC_ENDPOINT

const http = axios.create({
  // baseURL: 'https://manage.runcloud.io/api/v2',
  baseURL: URL + 'api/v2',
  headers: {
    'Authorization': 'Basic ' + btoa(`${API_KEY}:${SECRET}`),
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
})

export default {
  ping() {
    http.get('/ping')
  },

  async getServers() {
    try {
      const resp = await http.get('/servers')
      return resp.data
    } catch (e) {
      console.log(e.response)
    }
  },

  getUsers(serverId) {
    return http.get(`/servers/${serverId}/users`)
  },

  async createWebApp(serverId, data) {
    try{
      return await http.post(`/servers/${serverId}/webapps/custom`, data)  
    } catch (e) {
      return {error: true, msg: e.response.data.message, raw: e.response.data }
    }
    
  },

  async createDomain(data) {
    try{
      const { serverId, webAppId, domainName} = data
      console.log('ENDPOINT', `/servers/${serverId}/webapps/${webAppId}/domains`)
      return await http.post(`/servers/${serverId}/webapps/${webAppId}/domains`, {name: domainName})  
    } catch (e) {
      return {error: true, msg: e.response.data.message, raw: e.response.data }
    }
    
  },

  async getWebApps(serverId, opt) {
    try {
      const { sortBy, descending, page, itemsPerPage, search } = opt.options
      return await http.get(`servers/${serverId}/webapps?page=${page}&search=${search}`)   
    } catch (e) {
      console.log(e)
      alert('Error! Check console')
    }
  },

  async deleteWebApp(options) {
    try {
      const { serverId, webApp } = options
      return await http.delete(`servers/${serverId}/webapps/${webApp.id}`)   
    } catch (e) {
      console.log(e)
      alert('Error! Check console')
    }
  },

  async getWebAppDomains(serverId, webappid){
    try{
      return await http.get(`servers/${serverId}/webapps/${webappid}/domains`)
    } catch (e) {
      console.log(e)
      alert('Error! Check console')
    }
  },

  async getWebAppGit(serverId, webappid){
    try{
      return await http.get(`servers/${serverId}/webapps/${webappid}/git`)
    } catch (e) {
      console.log(e)
    }
  },

  async createWebAppGit( data ){
    let resp = {
      error: false,
      msg: ''
    }
    try{
      await http.post(`servers/${data.serverId}/webapps/${data.webAppId}/git`, data.git)
      return resp

    } catch (e) {
      console.log(e.response)
      // alert('Error! Check console')
      resp.error  = true
      resp.msg    = e.response.data.message
      return resp
    }

  },

  async searchWebApp(webapp) {
    
    let info = []
    let git, webhook, branch, repository

    const servers = await this.getServers()
    await servers.data.forEach( async (server) => {

      const app         = await http.get(`servers/${server.id}/webapps?search=${webapp.name}`)      
      const appSearch   = app.data.data.find( search => search.name == webapp.name ) 
      
      if (typeof appSearch !== 'undefined') {

        const _app        = appSearch
        const domains     = await http.get(`servers/${server.id}/webapps/${_app.id}/domains`)

        try{
          git         = await http.get(`servers/${server.id}/webapps/${_app.id}/git`)
          webhook     = (typeof git.data.id !== 'undefined' ) ? 
                          `https://manage.runcloud.io/webhooks/git/${_app.pullKey1}/${_app.pullKey2}` : null
          branch      = git.data.branch
          repository  = git.data.repositoryData.url

        } catch(err) {
          webhook     = null
          branch      = null
          repository  = null
        }
      
        info.push({
          serverId      : server.id,
          serverName    : server.name,
          webAppId      : _app.id,
          domains       : domains.data.data,
          git           : {
            webhook     : webhook,
            branch      : branch,
            repository  : repository
          } 
        })

      }
      
    })
    return info
  }

}
