<template>
  
    <v-card>
      <v-toolbar color="primary" dense dark>
        <v-toolbar-title>App Name: {{ webapp.name }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn text color="accent-4" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <v-card-text>
        <v-text-field color="success" loading disabled v-if="loading"></v-text-field>

        <div v-for="row in info" :key="row.serverId" class="mt-5">
          <v-row class="" no-gutters>
            <v-col lg="2">Server Name</v-col>
            <v-col>
              <span class="font-weight-bold">{{ row.serverName }}</span>
            </v-col>
          </v-row>
          <v-row class="align-center" no-gutters>
            <v-col lg="2">Registered Domains</v-col>
            <v-col class="text-left">  
              <v-btn x-small outlined class="mr-2"  v-for="domain in row.domains" :key="domain.id" color="primary"  @click="openUrl(domain.name)" >
                <!-- <a v-bind:href="'https://' + domain.name" target="_blank">{{ domain.name }}</a>  -->
                {{ domain.name }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2">WebHook</v-col>
            <v-col>
              <span v-if="row.git.webhook !== null">
                <span>Repository: {{ row.git.repository}} <br/>Branch: {{ row.git.branch }}</span><br/>
                <kbd >{{ row.git.webhook }}</kbd>
              </span>
              
              <span v-else>
                No GIT repository attached. <br/>
              </span>
            </v-col>
          </v-row>
        <v-divider></v-divider>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="showBtnCreate" @click="dialog=true" small dark color="teal" >Create Missing Git Webhooks</v-btn>
      </v-card-actions>

      <v-dialog v-model="dialog" max-width="500px">
        <WebAppGitForm @close="closeGitDialog" :info="info"></WebAppGitForm>
      </v-dialog>
    </v-card>


</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import WebAppGitForm from './WebAppGitForm'
export default {
  name: "WebAppDetail",
  components: { WebAppGitForm },
  props: ["webapp"],
  data() {
    return {
      info          : [],
      loading       : false,
      dialog        : false,
      showBtnCreate : false
    };
  },
  computed: {
    ...mapState({
        activeServer: state => state.runcloud.servers.find(
          server => server.id == state.runcloud.activeServer
      )},
      { 
        servers: state => state.runcloud.servers 
      },
      
    ) 
  },

  watch: {
    webapp: function() {
      this.getInfo()
    },
    info: function() {
      this.info.forEach( row => { 
        if (row.git.branch === null) {
          console.log(row.git)
          this.showBtnCreate = true
        }
        
      })
    }
  },

  mounted() {
    this.getInfo()
  },

  methods: {

    closeGitDialog() {
      this.dialog = false
      this.logs = []
      this.getInfo();
    },

    openUrl(domain) {
      const win = window.open('https://' + domain, '_blank');
      win.focus();

    },

    close() {
      this.$emit('close')
      this.info =  []
      this.showBtnCreate = false
    },

    getInfo() {
      this.loading = true
      this.$store.dispatch("runcloud/searchWebApp", this.webapp)
        .then ( resp => {
          this.info = resp    
          this.loading = false
        })

    }
  }
};
</script>