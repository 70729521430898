const tableHeader = [
    {
        text: 'WebApp',
        align: 'left',
        sortable: false,
        value: 'name'
    },
    // {
    //     text: 'User',
    //     align: 'left',
    //     sortable: false,
    //     value: 'server_user_id'
    // },
    {
        text: 'Created',
        align: 'right',
        sortable: false,
        value: '_created'
    },
    {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'actions'
    },
    
]

const webAppObject = {
    name: "",
    domainName: "",
    user: 0,
    publicPath: null,
    phpVersion: "php72rc",
    stack: "hybrid",
    stackMode: "production",
    clickjackingProtection: true,
    xssProtection: true,
    mimeSniffingProtection: true,
    processManager: "ondemand",
    processManagerMaxChildren: 50,
    processManagerMaxRequests: 500,
    openBasedir: "",
    timezone: "America/New_York",
    disableFunctions: "getmyuid,passthru,leak,listen,diskfreespace,tmpfile,link,ignore_user_abort,shell_exec,dl,set_time_limit,exec,system,highlight_file,source,show_source,fpassthru,virtual,posix_ctermid,posix_getcwd,posix_getegid,posix_geteuid,posix_getgid,posix_getgrgid,posix_getgrnam,posix_getgroups,posix_getlogin,posix_getpgid,posix_getpgrp,posix_getpid,posix,_getppid,posix_getpwuid,posix_getrlimit,posix_getsid,posix_getuid,posix_isatty,posix_kill,posix_mkfifo,posix_setegid,posix_seteuid,posix_setgid,posix_setpgid,posix_setsid,posix_setuid,posix_times,posix_ttyname,posix_uname,proc_open,proc_close,proc_nice,proc_terminate,escapeshellcmd,ini_alter,popen,pcntl_exec,socket_accept,socket_bind,socket_clear_error,socket_close,socket_connect,symlink,posix_geteuid,ini_alter,socket_listen,socket_create_listen,socket_read,socket_create_pair,stream_socket_server",
    maxExecutionTime: 30,
    maxInputTime: 60,
    maxInputVars: 1000,
    memoryLimit: 256,
    postMaxSize: 256,
    uploadMaxFilesize: 256,
    sessionGcMaxlifetime: 1440,
    allowUrlFopen: true          
  }

export {
    tableHeader,
    webAppObject
}