<template>
  
  <v-row justify="center">
    <v-col cols="12" md="10" >
      <WebAppsList @edit="viewDetails" @openForm="dialogForm=true" />
      <v-dialog :value="dialog" persistent max-width="1000px">
        <WebAppDetail :webapp="webapp" @close="dialog=false"></WebAppDetail>
      </v-dialog>
      <v-dialog :value="dialogForm" persistent max-width="600px">
        <WebAppForm @close="dialogForm = false"></WebAppForm>
      </v-dialog>
    </v-col>
    <!-- <v-col cols="3">
      <ServerList />
    </v-col> -->
  </v-row>

</template>

<script>
import { ServerList, WebAppsList, WebAppDetail, WebAppForm } from '@/modules/runcloud'
export default {
  
  components: { ServerList, WebAppsList, WebAppDetail, WebAppForm },

  mounted() {
    this.$store.dispatch('runcloud/getAllServers')
  },

  data() {
    return {
      dialogForm: false,
      dialog: false,
      webapp: {}
    }
  },

  methods: {
    viewDetails(webapp) {
      this.dialog = true
      this.webapp = webapp
    }
  }
  
};
</script>
