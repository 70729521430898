<template>
  <v-card>
    <v-card-title>Create Web App</v-card-title>
    <v-card-text>
        <form >
            <v-text-field
                v-model="form.clientFolder"
                label="Client Folder"
                class="ma-0 pa-0"
                dense
            ></v-text-field>
            <v-text-field
                v-model="form.domain"
                label="Domain Name"
                class="ma-0 pa-0"
                dense
            ></v-text-field>
            <v-textarea
                v-model="form.domainAlias"
                label="Domain Alias"
                hint="One per line"
                class="ma-0 pa-0"
                dense
            ></v-textarea>
            
            <v-checkbox v-for='server in servers' :key="server.id" class="ma-0 pa-0" small
                :value="server.id"
                v-model="form.servers"
                :label="server.name"
            ></v-checkbox>
        
        </form>

      
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
      <v-btn color="blue darken-1" @click="createWebApp" text>Create</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { webAppObject } from '@/modules/runcloud/config'


export default {
  name: "WebAppForm",

  computed:{
      ...mapGetters('runcloud',[
          'servers'
      ])
  },
  
  data() {
    return {
      form: {
        clientFolder  : '',
        domain        : '',
        domainAlias   : '',
        servers       : []
      },
      webAppData      : webAppData
          
    }
  },
  methods: {
    async createWebApp() {


      //create web app
      this.webAppData.name = this.form.clientFolder
      this.webAppData.domainName = this.form.domain
      this.openBasedir = `/home/symphony/webapps/${this.form.clientFolder}:/var/lib/php/session:/tmp:/home/symphony/webapps`

      const start = async() => {
        for (const serverId of this.form.servers) {

          //get users from current server
          const users = await this.$store.dispatch("runcloud/getUsers", serverId)
          const user = users.data.data.find( user => user.username == 'symphony')
          if (!user) continue //User not found, skip to next
          
          //create web app
          this.webAppData.user = user.id
          const result = await this.$store.dispatch('runcloud/createWebApp', {
            serverId: serverId,
            webApp: this.webAppData
          })
          if (result.error) {
            alert('Error: ' + result.msg)
            continue
          }

          const webapp = result.data

          //create domains alias
          let domainArray = this.form.domainAlias.split("\n")
          const server = this.servers.find( server => server.id = serverId)
          if ( server.name == 'DEV' || server.name == 'AWS DEV' ) {
            domainArray.push(
              `${this.form.clientFolder}.tambo.site`,
              `www.${this.form.clientFolder}.tambo.site`
            )  
          } else {
            domainArray.push(
              `www.${this.form.domain}`
            )  
          }

          for (const domain of domainArray) {

            if ( domain.trim().length == 0 ) continue

            const args = {
              serverId    : serverId,
              webAppId    : webapp.id,
              domainName  : domain
            }

            console.log('ARGS', args)
            const domainResult = await this.$store.dispatch('runcloud/createDomain', args)

            console.log(domainResult)

          }

          console.log('Result',result)
        }
        console.log('End');
      }

      start().then( () => {
        console.log('All Done!')
      }).catch( (err) => {
        console.log('Error', err)
      })



      //create domains

      //create git integration (optional
      
    },
    close() {
      this.$emit('close')
    }
  }
};
</script>