import runcloud from './api/runcloud.js'

const state = {
  systemUsers: [],
  servers: [],
  webapps: [],
  activeServer: 0
}

const getters = {
  getServerByName: state => {
    return state.servers.find( server => server.name == 'S1')
  },
  servers: state => {
    return state.servers.sort( (a,b) => (a.name > b.name) ? 1 : -1)
  },
  activeServer: state => {
    return state.servers.find( server => server.id == state.activeServer) 
  }
}

const actions = {

  fake( {commit}, args ) {
    return new Promise( (resolve, reject) => {
      setTimeout(() => {
        console.log('Store', args)
        resolve('OK')
      }, 2000);
    })
  },
  getAllServers({ commit }) {
    runcloud.getServers().then(resp => {
      commit("updateServers", resp.data);
    });
  },

  getUsers({state}, serverId) {
    return runcloud.getUsers(serverId)
  },

  getAllWebApps({ commit, state },options) {
    return runcloud.getWebApps(state.activeServer, options)
  },

  createWebApp( {state}, options) {
    return runcloud.createWebApp(options.serverId, options.webApp)
  },

  deleteWebApp( {state}, options) {
    return runcloud.deleteWebApp(options)
  },

  createDomain( {state}, options) {
    return runcloud.createDomain(options)
  },

  searchWebApp( {commit}, webapp ) {
    return runcloud.searchWebApp(webapp)
  },

  getWebAppDomain( { commit, state}, webAppId ) {
    return runcloud.getWebAppDomains(state.activeServer, webAppId)
  },

  getWebAppGit( { commit, state}, webAppId ) {
    return runcloud.getWebAppGit(state.activeServer, webAppId)
  },

  createWebAppGit( { commit, state }, data ){
    return runcloud.createWebAppGit(data)
  }

}

const mutations = {
  setActiveServer ( state, serverId ) {
    // const server = state.servers.find ( server => server.id = serverId)
    state.activeServer = serverId
  },
  updateServers( state, servers) {
    var filteredServers = servers.filter( server => {
      return (['tempo', 'tambo', 'aws tambo', 'aws tempo'].indexOf(server.name.toLowerCase()) === -1)  
    })
    state.servers = filteredServers
  },
  updateWebApps( state, webapps) {
    state.webapps = webapps
  }
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}