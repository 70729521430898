
const state = {
  user: {}
}

const getters = {}

const actions = {}

const mutations = {
  setUser(state, user) {
    state.user = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}