import Vue from 'vue'
import Router from 'vue-router'
import DashBoard from './views/DashBoard.vue'
import Login from './views/Login.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/',
      name: 'dashboard',
      component: DashBoard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const currentUser = Vue.prototype.$firebase.auth().currentUser;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (currentUser == null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    if ( ['login','register'].indexOf(to.name) == 0 && currentUser) {
      next({name: 'dashboard'})   
    } else {
      next()
    }
    
  }
})


export default router