<template>
  <v-app id="inspire">
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="secondary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <div class="flex-grow-1"></div>
                
                
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-model="form.email" label="Login" name="login" prepend-icon="mdi-account" type="text"></v-text-field>

                  <v-text-field v-model="form.password" id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password"></v-text-field>

                </v-form>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="secondary" @click="login">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String
  },
  methods: {
    login() {

      this.$firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
      .then( resp => {
        
        // console.log(user)
        if(this.$route.params.nextUrl != null){
            this.$router.push(this.$route.params.nextUrl)
        }
        else {  
          this.$router.push({name: 'dashboard'})
        }

      })
      .catch( err => {
        alert(err.message)
      })
      
    }
  },
  data: () => ({
    drawer: null,
    form: {
      email     : '',
      password  : ''
    }
  })
};
</script>