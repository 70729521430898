<template>
  <v-card>
    <v-card-title>Attach GIT repository to your Web Application</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field disabled v-model="form.user" label="GIT User"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field disabled v-model="form.host" label="GIT Host"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="form.repository" label="GIT Repository"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <p class="red lighten-5 red--text pa-3" v-for="log in logs" :key="log">{{log}}</p>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="blue darken-1" text @click="close" :disabled="loading">Close</v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="createGit"
        :loading="loading"
        :disabled="form.repository == '' || loading"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "WebAppGitForm",
  props: ["info"],
  data() {
    return {
      logs: [],
      loading: false,
      form: {
        user: "git",
        host: "git.tambourine.com",
        repository: ""
      }
    };
  },
  methods: {
    close() {
      this.logs = []
      this.$emit('close')
    },
    createGit() {
      this.logs = []
      this.info.forEach(async app => {
        this.loading = true
        if (app.git.webhook !== null) return;
        const data = {
          serverId: app.serverId,
          webAppId: app.webAppId,
          git: {
            provider: "custom",
            repository: this.form.repository,
            gitUser: this.form.user,
            gitHost: this.form.host,
            branch: app.serverName == "DEV" ? "dev" : "master"
          }
        };
        const resp = await this.$store.dispatch("runcloud/createWebAppGit",data);


        if (resp.error) {
          this.logs.push(`Error creating git on ${app.serverName}. ${resp.msg}`);
        } else {
          this.$emit('close')
          
        }

        this.loading = false

      });
      
    }
  }
};
</script>