<template>
  <v-app>
    <Navbar v-if="['login','register'].indexOf($route.name) == -1" />

    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Navbar from "@/views/partials/Navbar";

export default {
  name: "App",
  components: { Navbar },
  data: () => ({
    //
  }),
  created() {}
};
</script>
