import Vue from 'vue'
import Vuex from 'vuex'

import runcloud from '@/modules/runcloud/store.js'
import firebase from '@/modules/firebase/store.js'

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
      runcloud,
      firebase
    }
})
