const config = {
    apiKey: "AIzaSyDpPLzr7RjdkWKFzALhES6yJFbDwx183Dk",
    authDomain: "server-manager-83459.firebaseapp.com",
    databaseURL: "https://server-manager-83459.firebaseio.com",
    projectId: "server-manager-83459",
    storageBucket: "server-manager-83459.appspot.com",
    messagingSenderId: "1034078842788",
    appId: "1:1034078842788:web:9520493ea1793047c29b31"
  };

  export {
      config
  }
