<template>
  <v-dialog v-model="dialog" scrollable max-width="600" persistent>
    <v-card>
      <v-card-title>Duplicate WebApp: {{ webapp.name}}</v-card-title>
      <v-card-text class="mb-1 pb-1"><p class="mb-1">Select the destination servers and click on start</p></v-card-text>
      
      <v-divider></v-divider>  
      <v-card-text class="pt-2" >

        <v-row>
          <v-col cols="4">
            
            <v-checkbox v-for='server in servers' :key="server.id" class="ma-0 pa-0"  
              height="5"
              :value="server.id"
              v-model="selectedServers"
              :label="server.name"
            ></v-checkbox>
          </v-col>
          <v-col cols="8" class="text-center">            
            <v-btn v-if="!logs.length" color="blue darken-1" dark small @click="duplicate">Start</v-btn>

            <template v-if="logs.length" >
              <p v-for="(log, i) in logs" :key="i" :class=" (log.type == 'info' ? `indigo--text` : `red--text`) +  ` mb-1 text-left body-2`">
                {{ log.text }} 
                <v-progress-circular v-if="log.processing" indeterminate :size="15" :width="2"></v-progress-circular>  
                <v-icon :class=" (log.type == 'info' ? `indigo--text` : `red--text`)" :size="15" v-if="!log.processing">{{ log.icon }}</v-icon>
              </p>
            </template>
            
          </v-col>
        </v-row>
        
        
      </v-card-text>
      <v-divider></v-divider>
        <v-card-actions>
          <v-btn :disabled="processing" color="blue darken-1" text @click="dialog = false">Close</v-btn>
          
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { webAppObject } from '@/modules/runcloud/config'

export default {
  name: "WebAppDuplicateModal",
  props: ['show','webapp'],
  watch: {
    dialog(val) { val || this.clearLogs() },
    webapp() {
      // Get Domains
      this.$store.dispatch('runcloud/getWebAppDomain', this.webapp.id).then( (resp) => {
        this.domains = resp.data.data.sort( (a,b) => (a.id > b.id) ? 1 : -1)
      })

      // Get GIT Object
      this.$store.dispatch('runcloud/getWebAppGit', this.webapp.id).then( (resp) => {
        this.git = resp.data
      })
    }
  },
  computed: {
    ...mapGetters('runcloud', [
      'servers',
      'activeServer'
      ]),
    dialog: {
      get: function() { return this.show },
      set: function(value) { this.$emit('modalChange', value) }
    }
    
  },
  data() {
    return {
      selectedServers: [],
      processing: false,
      logs: [],
      domains: [],
      git: {},
      task: {
        serverId: '',
        webAppId: '',
      }
    }
  },
  methods: {
    clearLogs() {
      this.logs = []
      this.processing = false,
      this.selectedServers = []
    },
    duplicate() {

      if(this.selectedServers.length == 0) {
        alert('Please Select at least one server')
        return
      }

      const start = async() => {
        
        for( const serverId of this.selectedServers) {
          this.task.server = this.servers.find( server => server.id == serverId) 

          const users = await this.$store.dispatch('runcloud/getUsers', serverId)
          const defaultUser = users.data.data.find( user => user.username == 'symphony');
          
          //Create WebApp
          this.logs.push( { text: `Creating webApp in ${this.task.server.name}`, icon: 'mdi-check', type: 'info', processing: true} )

          webAppObject.user = defaultUser.id
          webAppObject.name = this.webapp.name
          webAppObject.domainName = this.domains[0].name
          webAppObject.openBasedir = `/home/symphony/webapps/${this.webapp.name}:/var/lib/php/session:/tmp:/home/symphony/webapps`

          const resp = await this.$store.dispatch('runcloud/createWebApp', {
            serverId: serverId,
            webApp: webAppObject
          })
          this.logs[this.logs.length-1].processing = false
          
          if (resp.error) {
            this.logs[this.logs.length-1].type  = 'error'
            this.logs[this.logs.length-1].icon  = 'mdi-alert-circle-outline'
            this.logs[this.logs.length-1].msg   = resp.msg
            console.log(resp)
            continue
          }

          const app = resp.data
          this.task.webAppId = app.id

          //Create Domains
          const domains = await this.createDomain()
          
          //Clone Git
          const git = await this.cloneGit()
          
        }
      }

      this.logs.push( { text: 'Initializing...', icon: '', type: 'info', processing: false} )
      this.processing = true
      
      start().then( () => {
        this.logs.push( { text: 'Done', icon: '', type: 'info', processing: false} )
        this.processing = false    
      })
      
    },

    async cloneGit() {
        this.logs.push( { text: `Cloning Git`, icon: 'mdi-check', type: 'info', processing: true} )
        
        const git = this.git.repositoryData.repo.split(":");
        const data = {
          serverId: this.task.server.id,
          webAppId: this.task.webAppId,
          git: {
            provider: "custom",
            repository: git[1].replace('.git',''),
            gitUser: 'git',
            gitHost: 'git.tambourine.com',
            branch: ( this.task.server.name.includes("DEV") ) ? 'dev' : 'master'
          }
        };
        const resp = await this.$store.dispatch("runcloud/createWebAppGit",data);

        this.logs[this.logs.length-1].processing = false

        if (resp.error) {
          this.logs[this.logs.length-1].type  = 'error'
          this.logs[this.logs.length-1].icon  = 'mdi-alert-circle-outline'
          this.logs[this.logs.length-1].msg   = resp.msg
          console.log(resp)
        }
        
    },

    async createDomain() {
      this.logs.push( { text: `Creating Domains`, icon: 'mdi-check', type: 'info', processing: true} )

      const args = {
          serverId  : this.task.server.id,
          webAppId  : this.task.webAppId
        }
        let i = 0
        for (const domain of this.domains) {
          if (i == 0) {i++; continue} //Ignore main domain. The main domain was already created in the webapp call
          args.domainName = domain.name
          const resp = await this.$store.dispatch('runcloud/createDomain', args)
          i++
        }

        this.logs[this.logs.length-1].processing = false
    }
  },
  mounted() {}

};
</script>